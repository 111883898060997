import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <header class="text-gray-600 body-font">
      <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <Link class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
          <h1 className="anvix-font text-4xl transition duration-700 ease-in-out font-light">
            A<u>nvi</u>x
          </h1>
        </Link>
      </div>
    </header>
  );
};

export default Navbar;
