import React, { useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import EmblaCarousel from "../EmblaCarousel/EmblaCarousel";

const Main = () => {
  return (
    <section class="text-black body-font h-full">
      <div class="container px-5 mx-auto flex flex-wrap items-center">
        <div class="lg:w-1/2 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
          <p class="mt-4 text-5xl leading-normal	 text-center font-light">
            One platform to manage your POS, Inventory, People, Payments and
            more...
          </p>
        </div>
        <div class="h-200 lg:w-1/2 md:w-1/2 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
          <EmblaCarousel />
        </div>
      </div>
      <div className="text-center">
        <p className="text-3xl font-light leading-10">
          Cooking something great.! <br /> Launching Soon..!!
        </p>
      </div>
    </section>
  );
};

export default Main;
