import useEmblaCarousel from "embla-carousel-react";
import React from "react";
import Autoplay from "embla-carousel-autoplay";
import slide1 from "../../assets/slide-c-1.jpg";
import slide2 from "../../assets/slide-c-2.jpg";
const EmblaCarousel = () => {
  const [emblaRef] = useEmblaCarousel({ loop: true }, [Autoplay()]);

  return (
    <div className="embla" ref={emblaRef}>
      <div className="embla__container">
        <div className="embla__slide">
          <img
            src={slide1}
            alt="scan-order"
          />
        </div>
        <div className="embla__slide">
          <img
            src={slide2}
            alt="scan-pay"
          />
        </div>
      </div>
    </div>
  );
};

export default EmblaCarousel;
