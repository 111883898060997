import React from "react";

const Footer = () => {
  return (
    <div className="bottom-footer text-center bottom-0 mt-8">
      <p className="text-sm m-0 p-0">All Rights Reserved. Anvix</p>
    </div>
  );
};

export default Footer;
